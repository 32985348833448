<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar class="px-1 pb-3">
        <ion-buttons class="mr-2 mt-2" slot="start">
          <ion-back-button
            color="primary"
            mode="ios"
            text=""
            default-href="/b2b/main/home"
            @click="goBack"
            :icon="chevronBackOutline"
          ></ion-back-button>
        </ion-buttons>
        <ion-row class="ion-align-items-center mt-2">
          <ion-col size="12" class="search-product" :class="isStartSearch ? 'new-search' : 'new-search-off'">
            <ion-searchbar
              id="search-text"
              mode="ios"
              ref="searchInput"
              @ionChange="onUpdateSearch(changeCategory)"
              @ionFocus="setSearch(true)"
              @ionBlur="setSearch(false)"
              show-clear-button="never"
              :debounce="1200"
              class="search"
              :placeholder="$t('search_all_products')"
              @keyup.enter="closeKeyboard"
            >
            </ion-searchbar>
            <ion-icon
              v-show="keySearch.length > 0"
              class="clear-search"
              :icon="closeCircle"
              @click="clearSearch"
            ></ion-icon>
          </ion-col>
        </ion-row>
        <!-- CART ICON -->
        <ion-buttons class="ml-1 mt-2" slot="primary">
          <ion-button class="btn-notify" @click="goToCartPage">
            <ion-icon color="primary" slot="icon-only" :icon="cartOutline"></ion-icon>
            <div v-if="lengthItems > 0" class="notify-count">
              <div class="notify-badge">{{ lengthItems }}</div>
            </div>
          </ion-button>
        </ion-buttons>
        <!-- END CART ICON -->
      </ion-toolbar>
      <div class="grey-lines" />
    </ion-header>

    <!-- Product category component -->
    <show-product-category
      v-if="showProductCategory"
      @chooseCategory="chooseCategory"
      @setListProductcategory="setListProductcategory"
    />

    <!-- search result -->
    <ion-content
      v-else
      ref="productListContent"
      :scroll-events="true"
      @ionScroll="checkScroll($event)"
      class="search-page"
      :scroll-y="!showProductSkeleton"
    >
      <!-- Tab menu area -->
      <div v-show="isShowTabOption">
        <div class="tab-bar py-2 floating-filter-tabs">
          <div class="tab-area" @click="selectedRelevant">
            <ion-text :class="isRelevantActive ? 'tab-text-on' : 'tab-text-off'">{{
              $t('relevant')
            }}</ion-text>
          </div>
          <div class="line-space mt-1"></div>
          <div class="tab-area" @click="selectedNewest">
            <ion-text :class="isNewestActive ? 'tab-text-on' : 'tab-text-off'">{{ $t('newest') }}</ion-text>
          </div>
        </div>
        <div class="row floating-filter-line">
          <div :class="isRelevantActive ? 'active-tab-line' : 'off-tab-line'"></div>
          <div :class="isNewestActive ? 'active-tab-line' : 'off-tab-line'"></div>
        </div>
      </div>

      <!-- skeleton loading of filter option menu -->
      <ion-grid class="floating-filter" :class="isShowTabOption ? 'top-margin-filter' : ''">
        <ion-row class="mt-2 mb-1 mx-2">
          <div
            class="px-2 py-1"
            :class="totalFilterCount > 0 ? 'filter-opt-on' : 'filter-opt-off'"
            @click="showFilterOption(true)"
          >
            <div v-if="totalFilterCount > 0" class="filter-count mr-1">{{ totalFilterCount }}</div>
            <ion-icon v-else icon="/assets/images/new_filter.svg" class="mx-1 filter-icon" />
            <ion-text>{{ $t('filter') }}</ion-text>
          </div>
          <!-- Hide favorite for  current release v1.0.0 -->
          <!-- <div
            class="px-2 py-1"
            :class="
              (isFavoriteActive && previousFilterData.isFavorite) || previousFilterData.isFavorite
                ? 'filter-opt-on'
                : 'filter-opt-off'
            "
            @click="selectedFavorites"
          >
            {{ $t('favorites') }}
          </div> -->
        </ion-row>
      </ion-grid>
      <div v-show="isShowTabOption" class="mx-3 mb-3 result-info top-space">
        <div
          v-show="showResultFrom && keySearch.length === 0"
          class="mt-3 mb-2"
          v-html="$t('result_from', { category_name: categoryName })"
        ></div>
        <div
          v-show="keySearch.length > 0"
          class="mt-3 mb-2"
          v-html="$t('result_for', { search_key: keySearch })"
        ></div>
      </div>

      <!-- show purchase headline -->
      <div class="mx-3 mb-3 top-space">
        <div v-show="list.totalCount > 0 && isRelevantActive" class="right-text mb-2">
          {{ $t('product_have_purchased') }}
        </div>
        <div v-show="list.totalCount > 0" class="grey-lines" />
      </div>

      <!-- skeleton loading of product item -->
      <div v-if="showProductSkeleton">
        <skeleton-product-item v-for="(item, index) in 4" :key="index" :detail="false" class="mx-3 my-3" />
      </div>
      <!-- Product item -->
      <div class="search-page">
        <!-- No result or no product found -->

        <div v-if="showNoResult" class="product-not-found mx-4">
          <ion-img src="/assets/images/not-found.svg" class="not-found-img" />
          <div
            class="not-found-text mt-3 mb-2"
            v-html="
              isConnectionIssue
                ? $t('unable_load')
                : keySearch.length > 0
                ? $t('sorry_not_found', {
                    search_keyword: search?.searchQueries === '' ? keySearch : search?.searchQueries
                  })
                : $t('sorry_not_any_products')
            "
          ></div>
          <div class="try-different">
            {{ isConnectionIssue ? $t('please_make_sure_connected') : $t('try_different_keyword') }}
          </div>
        </div>
        <!-- Purchased products item -->
        <div v-if="list?.totalCount > 0">
          <div slot="content" v-for="item in list?.items" :key="item.id">
            <NewProductItem
              :id="'purchasedProducts'"
              :item="item"
              :user="user"
              :selectedCompany="selectedCompany"
              @item-clicked="toProductDetail"
            />
          </div>
        </div>

        <!-- Show more product only for Relevant Tab Menu -->

        <div v-show="isRelevantActive && showMoreProducts && moreProductList.totalCount > 0">
          <div class="mx-3 my-4">
            <div class="right-text mb-2">{{ $t('more_products') }}</div>
            <div class="grey-lines" />
          </div>

          <div>
            <div slot="content" v-for="item in moreProductList?.items" :key="item.id">
              <NewProductItem
                :id="'moreAllProducts'"
                :item="item"
                :user="user"
                :selectedCompany="selectedCompany"
                @item-clicked="toProductDetail"
              />
            </div>
          </div>
        </div>
        <!-- INFINITY scroll -->
        <ion-infinite-scroll
          @ionInfinite="loadMore($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="isDisabledLoadMore"
        >
          <div class="d-flex justify-center align-center pb-2">
            <ion-spinner name="bubbles"></ion-spinner>
            <ion-text class="ml-1">{{ $t('please_wait') }}...</ion-text>
          </div>
        </ion-infinite-scroll>
      </div>
    </ion-content>
    <!-- END search result -->

    <!-- filter modal -->
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="bottom-sheet-modal"
      :is-open="isOpenFilterOption"
      @didDismiss="showFilterOption(false)"
    >
      <filter-option
        @closeModal="showFilterOption(false)"
        @applyFilterData="applyFilterData"
        :previousFilterData="previousFilterData"
      />
    </ion-modal>
  </ion-page>
</template>
<script>
import { TYPE_SEARCH, defaultSize } from '@/modules/b2b/constants';
import { ACTIONS as ACTIONS_NOTIFICATION } from '@/modules/b2b/store/notification/actions';
import { ACTIONS as ACTIONS_PRODUCT_SEARCH } from '@/modules/b2b/store/product-search/actions';
import { MUTATIONS as MUTATIONS_PRODUCT_SEARCH } from '@/modules/b2b/store/product-search/mutations';
import { OFFSET_LIST_DEFAULT } from '@/modules/shared/constants/';
import Clevertap from '@/services/shared/helper/clevertap';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useBackButton } from '@ionic/vue';
import { cartOutline, chevronBackOutline, closeCircle } from 'ionicons/icons';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapGettersBadge, mapActions: mapActionsNotification } = createNamespacedHelpers(
  'b2b/notification'
);
const { mapGetters: mapGettersCustomer } = createNamespacedHelpers('b2b/customer');
const {
  mapActions: mapActionsProductList,
  mapGetters: mapGettersProductList,
  mapMutations: mapMutationsProductList
} = createNamespacedHelpers('b2b/productSearch');

export default defineComponent({
  components: {
    // components
    SkeletonProductItem: defineAsyncComponent(() => import('../SkeletonProductItem.vue')),
    ShowProductCategory: defineAsyncComponent(() => import('./ShowProductCategory.vue')),
    FilterOption: defineAsyncComponent(() => import('./FilterOption.vue')),
    NewProductItem: defineAsyncComponent(() => import('@/components/product-item/NewCardProductItem.vue'))
  },
  setup() {
    const isGoBack = ref(false);
    useBackButton(10, () => {
      isGoBack.value = true;
    });
    return {
      Keyboard,
      chevronBackOutline,
      cartOutline,
      closeCircle,
      isGoBack
    };
  },
  computed: {
    ...mapGettersBadge(['numberBadge']),
    ...mapGettersCustomer(['selectedCustomer']),
    ...mapGettersProductList([
      'loading',
      'list',
      'moreProductList',
      'search',
      'searchMore',
      'type',
      'isLoadMore',
      'getProductStatus'
    ]),
    lengthItems() {
      return this.numberBadge && this.numberBadge.carts;
    },
    showResultFrom() {
      return (
        this.changeCategory !== 'favourite' &&
        !this.showProductSkeleton &&
        this.categoryName.length > 0 &&
        this.previousFilterData.categoriesSelected.length === 1
      );
    },
    isDisabledLoadMore() {
      let isDisable = false;
      if (!this.isRelevantActive) {
        isDisable = this.list.items.length === this.list.totalCount;
      } else {
        isDisable = this.moreProductList.items.length === this.moreProductList.totalCount;
      }
      return isDisable;
    }
  },
  data() {
    return {
      showProductCategory: true,
      isSearchByCategory: false,
      keySearch: '',
      isStartSearch: false,
      selectedCompany: null,
      user: null,
      showProductSkeleton: true,
      showSecondContent: false,
      changeCategory: 'history',
      selectedCategory: 'history',
      prevSelectedCategory: '',
      categoryName: '',
      NoLoadProducts: false,
      isShowTabOption: true,
      isFavoriteActive: false,
      isRelevantActive: true,
      isNewestActive: false,
      isOpenFilterOption: false,
      listProductCategory: [],
      totalFilterCount: 0,
      previousFilterData: {
        sort: '',
        sortId: 0,
        isWithPrice: false,
        isFavorite: false,
        isAll: false,
        categoriesSelected: [],
        totalSelectedFilter: 0
      },
      showMoreProducts: false,
      isConnectionIssue: false,
      isFirstRenderPage: true,
      showNoResult: false,
      isGoToProductDetails: false,
      firstSelectedCatgeories: [],
      previousPath: ''
    };
  },
  inject: ['$storage'],

  async ionViewWillEnter() {
    this.isGoToProductDetails = false;
    const historyState = window.history.state?.forward;
    this.previousPath = historyState?.substring(0, historyState?.lastIndexOf('/'));

    // get some needed info
    this.user = await this.$storage.getUser();
    this.selectedCompany = await this.$storage.getSelectedCompany();
    await this.getNumberBadge();
  },
  async ionViewDidEnter() {
    await this.loadFirstSearchContent();
  },
  async ionViewWillLeave() {
    this.closeKeyboard();
    if (!this.isGoToProductDetails) {
      await this[MUTATIONS_PRODUCT_SEARCH.RESET_CATEGORY]();
      await this.setSearchParamsBackToDefault();
      this.resetSearchPage();
    }
  },
  methods: {
    ...mapActionsNotification([ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]),
    ...mapActionsProductList([
      ACTIONS_PRODUCT_SEARCH.UPDATE_SEARCH,
      ACTIONS_PRODUCT_SEARCH.GET_MORE_PRODUCTS,
      ACTIONS_PRODUCT_SEARCH.GET_PRODUCTS
    ]),
    ...mapMutationsProductList([
      MUTATIONS_PRODUCT_SEARCH.RESET_CATEGORY,
      MUTATIONS_PRODUCT_SEARCH.RESET_SEARCH_RESULT
    ]),
    async getNumberBadge() {
      this.selectedCompany?.id &&
        (await this[ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]({
          customerId: this.selectedCompany.id
        }));
    },
    goToCartPage() {
      this.$router.replace('/b2b/cart');
    },

    async onUpdateSearch(mainCategoryId) {
      this.showProductCategory = false;
      // reset serach result
      this.isDisabledLoadMore = true;
      await this[MUTATIONS_PRODUCT_SEARCH.RESET_SEARCH_RESULT]();

      // update search key
      const currentSearchText = this.$refs.searchInput.$el?.value;
      this.keySearch = currentSearchText;

      // if search key = '' and not search by category then go to category page again
      if (!this.keySearch && !this.isSearchByCategory) {
        this.resetSearchPage();
        return;
      }

      // show product skeleton
      this.showProductSkeleton = true;
      if (this.keySearch.length > 0 || this.isSearchByCategory) {
        this.showNoResult = false;
        this.showSecondContent = true;
        if (this.keySearch.length > 0) {
          this.isSearchByCategory = false;
        }
        await this.onSearch(mainCategoryId);
      }
    },
    async onSearch(mainCategoryId) {
      // send data to clevertap
      Clevertap.onUserSearchProduct(this.keySearch);

      // perform reset category
      this[MUTATIONS_PRODUCT_SEARCH.RESET_CATEGORY]();

      // scroll page to the top
      this.isShowTabOption = true;
      const content = this.$refs.productListContent?.$el;
      content.scrollToTop();

      // update search condition
      await this[ACTIONS_PRODUCT_SEARCH.UPDATE_SEARCH]({
        search: {
          ...this.search,
          tenantId: 'joined',
          buyerId: this.selectedCompany.id + '',
          halal: this.selectedCompany.halal_products,
          account_number: this.selectedCompany.account_number,
          limit: defaultSize,
          searchQueries: this.keySearch,
          offset: OFFSET_LIST_DEFAULT,
          isIncludePurchaseHistory: true,
          hasPrice: this.previousFilterData.isWithPrice ? this.previousFilterData.isWithPrice : false,
          categories: this.previousFilterData.categoriesSelected
            ? this.previousFilterData.categoriesSelected
            : [],
          sortBy: this.previousFilterData.sort
            ? this.previousFilterData.sort
            : this.isNewestActive
            ? 'newest'
            : 'most_relevant',
          sortType: 'desc',
          mainCategoryId: this.previousFilterData.isFavorite
            ? 'favourite'
            : this.previousFilterData.isAll && !this.previousFilterData.isFavorite && this.isRelevantActive
            ? 'history'
            : this.previousFilterData.isAll && !this.previousFilterData.isFavorite && !this.isRelevantActive
            ? 'all'
            : mainCategoryId
            ? mainCategoryId
            : 'history'
        },
        type: TYPE_SEARCH.INPUT
      });
    },

    async setSearchParamsBackToDefault() {
      await this[ACTIONS_PRODUCT_SEARCH.UPDATE_SEARCH]({
        search: {
          ...this.search,
          searchQueries: '',
          mainCategoryId: 'history',
          sortBy: 'purchased',
          sortType: 'desc',
          categories: [],
          offset: 0
        },
        type: TYPE_SEARCH.CATEGORY
      });
    },

    async getMoreProductList() {
      if (this.searchMore.searchQueries !== this.keySearch) {
        this.searchMore.searchQueries = this.keySearch;
      }
      this.searchMore.searchQueries = this.keySearch;
      this.searchMore.buyerId = `${this.selectedCompany?.id}`;
      this.searchMore.offset = OFFSET_LIST_DEFAULT;
      this.searchMore.hasPrice = this.previousFilterData.isWithPrice
        ? this.previousFilterData.isWithPrice
        : false;
      this.searchMore.categories = this.previousFilterData.categoriesSelected
        ? this.previousFilterData.categoriesSelected
        : [];
      this.searchMore.sortBy = this.previousFilterData.sort ? this.previousFilterData.sort : 'most_relevant';
      this.searchMore.mainCategoryId = 'all';

      await this[ACTIONS_PRODUCT_SEARCH.GET_MORE_PRODUCTS]({
        isLoadMore: this.isLoadMore,
        searchMore: this.searchMore
      });
    },

    async loadFirstSearchContent() {
      if (this.previousPath !== '/b2b/main/product-detail') {
        this.setSearchBarFocus();
        // reset list & more product list
        await this[MUTATIONS_PRODUCT_SEARCH.RESET_SEARCH_RESULT]();
      } else {
        this.showProductCategory = false;
        this.showSecondContent = true;
      }
    },

    //handle show and hide tabs menu when user scrolling
    checkScroll(event) {
      this.closeKeyboard();
      if (event.detail?.deltaY > 270) {
        this.isShowTabOption = false;
      } else if (event.detail?.deltaY < -270 || event.detail?.scrollTop < 100) {
        this.isShowTabOption = true;
      }
    },

    toProductDetail(item) {
      this.isGoToProductDetails = true;
      Clevertap.onUserViewProductDetail(item);
      this.$router.push({
        path: `/b2b/main/product-detail/${item.id}`
      });
    },
    async clearSearch() {
      this.resetSearchPage();
      this.setSearchBarFocus();
    },
    setSearchBarFocus() {
      this.$nextTick(() => {
        const searchbar = this.$refs.searchInput.$el;
        searchbar.setFocus();
      });
    },
    setSearchBarBlur() {
      this.$nextTick(() => {
        const searchbar = this.$refs.searchInput.$el;
        searchbar.blur();
      });
    },
    closeKeyboard() {
      this.$nextTick(async () => {
        if (Capacitor.isNativePlatform()) {
          this.Keyboard.hide();
          // update search key
          const currentSearchText = this.$refs.searchInput.$el?.value;
          if (this.search.searchQueries !== currentSearchText) {
            this.keySearch = currentSearchText;
            await this.onUpdateSearch(this.selectedCategory);
          }
        }
      });
    },
    setSearch(params) {
      this.isStartSearch = params;
    },
    async chooseCategory(category) {
      this.resetSelectedCategories();
      this.isSearchByCategory = true;
      this.showProductCategory = false;
      this.showSecondContent = true;
      this.showProductSkeleton = true;
      this.selectedCategory = 'history';
      this.prevSelectedCategory = 'history';
      this.firstSelectedCatgeories = [category.id];
      this.previousFilterData.categoriesSelected = [category.id];
      this.totalFilterCount++;
      this.categoryName = category.name;
      await this.onUpdateSearch(this.selectedCategory);
    },

    resetSelectedCategories() {
      this.totalFilterCount = 0;
      this.firstSelectedCatgeories = [];
      this.previousFilterData.categoriesSelected = [];
    },

    resetVariableToDefault() {
      this.showProductCategory = true;
      this.resetSelectedCategories();
      this.resetSearchPage();
      this.showSecondContent = false;
      this.showProductSkeleton = false;
      this.isNewestActive = false;
      this.isRelevantActive = true;
    },

    async selectedFavorites() {
      this.isFavoriteActive = !this.isFavoriteActive;
      this.previousFilterData.isFavorite = !this.previousFilterData.isFavorite;
      this.showMoreProducts = this.isFavoriteActive ? false : true;
      this.changeCategory =
        this.isFavoriteActive && this.previousFilterData.isFavorite
          ? 'favourite'
          : this.prevSelectedCategory
          ? this.prevSelectedCategory
          : 'history';

      await this.onUpdateSearch(
        this.isFavoriteActive && this.previousFilterData.isFavorite
          ? 'favourite'
          : this.prevSelectedCategory
          ? this.prevSelectedCategory
          : 'history'
      );
    },
    async selectedRelevant() {
      if (!this.isRelevantActive || this.totalFilterCount === 0) {
        this.setSearchBarBlur();
        this.isNewestActive = false;
        this.isRelevantActive = true;
        this.changeCategory =
          this.prevSelectedCategory && this.totalFilterCount > 0 ? this.prevSelectedCategory : 'history';
        await this.onUpdateSearch(this.changeCategory);
      }
    },
    async selectedNewest() {
      if (!this.isNewestActive) {
        this.setSearchBarBlur();
        this.isRelevantActive = false;
        this.isNewestActive = true;
        this.changeCategory = 'all';
        await this.onUpdateSearch('all');
      }
    },

    showFilterOption(params) {
      this.isOpenFilterOption = params;
    },

    setListProductcategory(listData) {
      this.listProductCategory = listData;
    },

    async applyFilterData(filterData) {
      this.previousFilterData = { ...filterData };
      this.totalFilterCount = filterData.totalSelectedFilter;
      if (this.totalFilterCount === 0) {
        // when user reset filter and then filter count will be 0, so move it back relevant tabs menu
        this.categoryName = '';
        this.selectedRelevant();
      } else {
        // get name of selected category
        if (this.previousFilterData.categoriesSelected.length === 1) {
          const selectedCategory = this.listProductCategory?.find(
            (category) => category.id === this.previousFilterData.categoriesSelected[0]
          );

          if (selectedCategory) {
            // Category found
            const categoryName = selectedCategory.name;
            this.categoryName = categoryName;
          }
        }
        await this.onUpdateSearch(
          filterData.isFavorite
            ? 'favourite'
            : filterData.isAll && !filterData.isFavorite
            ? 'all'
            : this.changeCategory
        );
      }
    },

    async loadMore(e) {
      if (this.list.totalCount && this.list.items.length < this.list.totalCount) {
        // load more for purchase product
        await this[ACTIONS_PRODUCT_SEARCH.UPDATE_SEARCH]({
          search: {
            offset: this.search.offset + defaultSize
          },
          isLoadMore: true,
          type: TYPE_SEARCH.CATEGORY
        });
      } else if (this.moreProductList.totalCount && this.isRelevantActive) {
        // load more for more product section
        await this.loadMoreProducts();
      }
      e?.target.complete();
    },

    async loadMoreProducts() {
      let offsetSize = this.searchMore.offset;
      this.searchMore.offset = offsetSize += defaultSize;
      await this[ACTIONS_PRODUCT_SEARCH.GET_MORE_PRODUCTS]({
        isLoadMore: true,
        searchMore: this.searchMore
      });
    },
    async goBack() {
      await this.setSearchParamsBackToDefault();
      if (this.showSecondContent) {
        // Back to search landing page and clear search key
        this.resetVariableToDefault();
      } else {
        // Back to home epage
        this.$router.push({
          path: '/b2b/main/home'
        });
      }
      this.isGoBack = false;
      this.setSearchBarFocus();
    },
    resetSearchPage() {
      this.listProductCategory = [];
      this.previousFilterData.sort = '';
      this.previousFilterData.sortId = 0;
      this.previousFilterData.isWithPrice = false;
      this.previousFilterData.isFavorite = false;
      this.isFavoriteActive = false;
      this.previousFilterData.isAll = false;
      this.previousFilterData.categoriesSelected = [];
      this.previousFilterData.totalSelectedFilter = 0;
      this.keySearch = '';
      this.showProductCategory = true;
      this.showSecondContent = false;

      // clear search text
      document.getElementById('search-text').value = '';
    }
  },
  watch: {
    getProductStatus() {
      let isError = this.getProductStatus;
      this.isConnectionIssue =
        isError === this.$t('load_failed') || isError === this.$t('failed_to_fetch') ? true : false;
    },
    selectedCustomer: {
      async handler(newCompany, oldCompany) {
        if (oldCompany && newCompany && newCompany.id !== oldCompany.id) {
          this.selectedCategory = 'history';
        }
      },
      deep: true
    },
    selectedCategory() {
      this.changeCategory = this.selectedCategory;
    },

    async search() {
      await this[ACTIONS_PRODUCT_SEARCH.GET_PRODUCTS]({
        isLoadMore: this.isLoadMore
      });
    },

    async list() {
      this.showMoreProducts = false;
      this.isFirstRenderPage = false;
      if (!this.isRelevantActive) {
        this.showNoResult = this.list.totalCount === 0;
        this.showProductSkeleton = false;
      } else if (this.list.totalCount === this.list.items.length) {
        // call get more product section
        await this.getMoreProductList();
      } else {
        // show product skeleton
        this.showProductSkeleton = false;
      }
    },

    async moreProductList() {
      this.isFirstRenderPage = false;
      this.showMoreProducts = true;
      this.showNoResult =
        this.list.totalCount === 0 &&
        (this.moreProductList.totalCount === 0 || this.moreProductList.items.length === 0)
          ? true
          : false;
      // show product skeleton
      this.showProductSkeleton = false;
    },
    isFavoriteActive() {
      if (this.isFavoriteActive && this.previousFilterData.isFavorite) {
        this.totalFilterCount++;
      } else {
        this.totalFilterCount--;
      }
    },
    async isGoBack() {
      if (this.isGoBack) {
        await this.goBack();
      }
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
